<template>
<div class="alert-inner">
    <i class="uil uil-exclamation-triangle alert-icon"></i>
    <div class="alert-text">{{ options.message }}</div>
    <div class="alert-buttons">
        <button class="btn-danger btn" :style="options.style" @click="confirmation_emit(options.yes.value)">{{ options.yes.text }}</button>
        <button class="btn-default btn" :style="options.style" @click="confirmation_emit(options.no.value)">{{ options.no.text }}</button>
    </div>
</div>
</template>

<style lang="scss">
.modal[data-component="ConfirmModal"] .modal-header {
    background: #fff !important;
    border: 0 !important;

    .modal-title {
        visibility: hidden !important;
    }

    button {
        border: 0 !important;
    }
}
</style>

<style lang="scss" scoped>
.alert-inner {
    padding: 25px;
    padding-bottom: 60px;
    text-align: center;

    .alert-text {
        font-size: 17px;
        margin: 40px 0;
    }

    .alert-icon {
        font-size: 65px;
        color: #9e9e9e;
    }
}
</style>

<script>
// این کامپوننت برای استفاده کانفرم باکس رایجی که داخل داشبورد است استفاده میبشود
export default {
    name: 'ConfirmModal',
    props: ['data'],
    data() {
        return {
            confirmed: false,
            options: {
                message: '',
                yes: {
                    text: 'بله',
                    value: true,
                },
                no: {
                    text: 'خیر',
                    value: false,
                },
                style: {
                    width: '80px'
                }
            }
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.options.message = this.data.message;

            if (typeof this.data.btnWidth != 'undefined') {
                this.options.style.width = this.data.btnWidth;
            }

            if (typeof this.data.yes != 'undefined' && typeof this.data.yes.text != 'undefined') {
                this.options.yes.text = this.data.yes.text;
            }
            if (typeof this.data.yes != 'undefined' && typeof this.data.yes.value != 'undefined') {
                this.options.yes.value = this.data.yes.value;
            }
            if (typeof this.data.no != 'undefined' && typeof this.data.no.text != 'undefined') {
                this.options.no.text = this.data.no.text;
            }
            if (typeof this.data.no != 'undefined' && typeof this.data.no.value != 'undefined') {
                this.options.no.value = this.data.no.value;
            }
        },
        // وطیفه این متد ارسال درخواست پذیرفتن یا کانفرم به پرنت جهت کال بک میباشد
        confirmation_emit(confirmed) {
            this.confirmed = confirmed;
            this.$parent.emitData('confirmed', { confirmed }, true);
            this.reset();
        },
        reset() {
            this.message = '';
            this.confirmed = false;
        },
    },
}
</script>

<style lang="scss">
	#confirm-modal {

.confirm-text {

text-align: justify;

font-size: 17px;

}

.confirm-title {

font-weight: 500;

font-size: 17px;

margin-bottom: 15px;

}

.confirm-title:before {

content: '';

display: inline-block;

width: 10px;

height: 10px;

background: #4a9cfa;

margin-left: 5px;

border-radius: 3px;

position: relative;

top: 1px;

}

.buttons .btn {

padding: 8px 15px;

}
	}
</style>
